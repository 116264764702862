import React, {useState} from 'react'
import styles from './signup_card.module.scss'
import {Card, TextField, Button, Snackbar, SnackbarContent} from '@material-ui/core'
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import config from './../../config'
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {Link, navigate} from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import Lottie from 'lottie-react-web'
import animation from './../../animations/loading-cowork2.json'


const SignupCard = () => {
	const dispatch = useDispatch()
	const [userData, setUserData] = useState({
		'name': '',
		'lastname': '',
		'email': '',
		'phone': '',
		'password': '',
		'confirmPassword': '',
	})
	const [openSnack, setOpenSnack] = useState(false)
	const [errorMessage, setErrorMessage] = useState({
		'message': '',
		'color': ''
	})

	const [loadingRequest, setLoadingRequest] = useState(false)

	const handleSign = (event,name) => {
		setUserData({...userData, [name]: event.target.value});
	}

	const handleSubmit = async() => {
		if(!validateValues(userData)){
			setLoadingRequest(true)
			try{
				const response = await fetch(`${config.server}/users`,{
					method: 'POST',
					body: JSON.stringify({
						name: userData.name,
						lastname: userData.lastname,
						email: userData.email,
						phone: userData.phone,
						password: userData.password,
						user_type: 'client'
					}),
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const statusRequest = await response.json()
				setLoadingRequest(false)
				if(statusRequest.state == "EMAIL_REPEAT"){
					setErrorMessage({'message':'Ya existe una cuenta con este correo.','color':'#d32f2f'})
					setOpenSnack(true)
				}else if(statusRequest.state == "SUCCESS"){
					setUserData({
						'name': '',
						'lastname': '',
						'email': '',
						'phone': '',
						'password': '',
						'confirmPassword': '',
					})
					setErrorMessage({'message':'Cuenta creada exitosamente, seras redireccionado en unos segundos...','color':'#43a047'})
					setOpenSnack(true)
					dispatch({type: 'user/SET_USER_DATA', data: statusRequest.user})
					dispatch({type: 'user/SET_LOGGED', data: true})
					dispatch({type: 'user/SET_CONFIRM_EMAIL', data: false})

					try{
						const response = await fetch(`${config.server}/getLogin`,{
							method: 'POST',
							body: JSON.stringify({
								email: userData.email,
								password: userData.password,
								remember: false
							}),
							headers: {
								'Content-Type': 'application/json'
							}
						})
						const statusRequest = await response.json()
						document.cookie = `jwtoken=${statusRequest.token}; expires= ${new Date(statusRequest.exp)}`
					}catch(e){
						console.log(e)
					}
					navigate('/filter')
				}
			}catch(e){
				console.log(e)
			}
		}
	}

	const validateValues = (userData) => {
		let flag = false
		let valuesData = Object.values(userData)

		for(let i = 0; i < valuesData.length; i++){
			if(/^\s*$/.test(valuesData[i])){
				flag = true;
				setErrorMessage({'message':'Faltan datos por llenar.','color':'#d32f2f'});
				setOpenSnack(true);
			}
		}

		if(flag) return flag;

		if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userData.email)){
			setErrorMessage({'message':'Email invalido.','color':'#d32f2f'})
			setOpenSnack(true)
			flag = true
		}else if(userData.password !== userData.confirmPassword){
			setErrorMessage({'message':'Las contraseñas no coinciden.','color':'#d32f2f'})
			setOpenSnack(true)
			flag = true
		}else if((userData.password.length < 8 || userData.password.length > 20) || (userData.confirmPassword.length < 8 || userData.confirmPassword.length > 20)){
			setErrorMessage({'message':'La contraseña debe ser mayor a 8 y menor a 20 caracteres.','color':'#d32f2f'})
			setOpenSnack(true)
			flag = true
		}
		return flag
	}


	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: require('./../../animations/3678-fluid-loading-animation.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};


	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={styles.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={styles.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={styles.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}


	return(
		<Card className={styles.container}>
			<h2>Registrar</h2>
			<ThemeProvider theme={theme}>
				<TextField
			        label="Nombre"
			        className={styles.textField}
			        margin="normal"
			        variant="outlined"
					value={userData.name}
					onChange={(e) => handleSign(e, 'name')}
		        />
				<TextField
					label="Apellidos"
					className={styles.textField}
					margin="normal"
					variant="outlined"
					value={userData.lastname}
					onChange={(e) => handleSign(e, 'lastname')}
				/>
				<TextField
					label="Email"
					className={styles.textField}
					margin="normal"
					variant="outlined"
					type="email"
					value={userData.email}
					onChange={(e) => handleSign(e, 'email')}
				/>
				<TextField
					label="Telefono"
					className={styles.textField}
					margin="normal"
					variant="outlined"
					type="number"
					value={userData.phone}
					onChange={(e) => handleSign(e, 'phone')}
				/>
				<TextField
			        label="Contraseña"
			        className={styles.textField}
			        margin="normal"
					type="password"
					value={userData.password}
			        variant="outlined"
					onChange={(e) => handleSign(e, 'password')}
			    />
				<TextField
					label="Confirmar Contraseña"
					className={styles.textField}
					margin="normal"
					type="password"
					variant="outlined"
					value={userData.confirmPassword}
					helperText="Debe ser de 8-20 carácteres."
					onChange={(e) => handleSign(e, 'confirmPassword')}
				/>
			</ThemeProvider>
			<Button onClick={handleSubmit} className={styles.buttonSignup} variant="contained">Registrarme</Button>
			<p className={styles.finalText}>¿Ya tienes una cuenta Cowork? <Link to="/login">Entra aqui</Link></p>
			<SnackBarWrapper message={errorMessage.message}/>
				{
	                loadingRequest ? (
	                    <div className='loottieAnimation'>
	                        <Lottie
	                            options={{
	                              animationData: animation
	                            }}
	                            style={{
	                                position: 'fixed',
	                                left: '50%',
	                                zIndex: 1000,
	                                background: 'rgba(0,0,0,0.2)',
	                                transform: 'translateX(-50%) translateY(-50%)',
	                                top: '50%'
	                            }}
	                          />
	                    </div>
	                ): (null)
	            }
		</Card>
	)
}


const theme = createMuiTheme({
	overrides: {
      MuiOutlinedInput: {
          root: {
              position: 'relative',
			  color:'#000',
              '& $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                      borderColor: 'rgba(0,0,0,0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#DE892A',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
          root: {
			  color:'rgba(0,0,0,0.54)',
              '&$focused': {
                  color: '#DE892A'
              }
          }
      }}
});


export default SignupCard
