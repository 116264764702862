import React from 'react'
import styles from './styles/signup.module.scss'
import HeaderBack from "../components/HeaderBack"
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {navigate} from 'gatsby'
import {useSelector} from 'react-redux'
import SignupCard from '../components/SignupCard'
import './styles/lottie.css'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));

const Signup = () => {
	const classes = useStyles();
	const isLogged = useSelector(state => state.user.isLogged)

	if(isLogged){
		navigate('/')
	}
	return(
		<Layout>
			<SEO title="Registrarse" />
			<HeaderBack
				title="Registrarse"
				description="Ingresa con tu cuenta cowork entre todos,si no tienes una puedes Unirte ¡Es grátis!"
				image={require('./../images/ingresar.jpg')}
			/>
			<div className={classes.root}>
				<Grid container spacing={3}
				style = {{
					justifyContent: "center",
					display: "flex",
					marginTop: "1rem"
				}}>
					<Grid item xs={11} md = {5}>
					<SignupCard/>
					</Grid>
				</Grid>
			</div>
			{/* <div className={styles.signupContainer}>
				<SignupCard/>
			</div> */}
		</Layout>
	)
}

export default Signup
